import { Separator as RadixSeparator } from '@radix-ui/react-separator'

export function VerticalSeparator({ margin }: { margin?: string }) {
	return (
		<RadixSeparator
			orientation="vertical"
			className="hidden bg-gray-400 dark:bg-white lg:block print:hidden"
			style={{
				margin: margin ?? '0 16px',
				height: '100%',
				width: '1px',
			}}
			decorative
		/>
	)
}

export function HorizontalSeparator({ margin }: { margin?: string }) {
	return (
		<RadixSeparator
			orientation="horizontal"
			className="bg-gray-300 dark:bg-gray-700 sm:block print:hidden"
			style={{
				margin: margin ?? '16px 0',
				width: 'inherit',
				minWidth: '100%',
				height: '1px',
			}}
			decorative
		/>
	)
}
